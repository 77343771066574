<template>
  <ManageAgency dialog-name="Agencies" />
</template>
<script>
import ManageAgency from '@/views/app/manage/Agency'

export default {
  name: 'AgencyWrapper',
  components: {
    ManageAgency
  }
}
</script>
